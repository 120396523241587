import {
	SYSTEM_TEMPLATES_FETCHED,
	SYSTEM_FETCHING_TEMPLATES,
	SYSTEM_DELETING_TEMPLATE,
	SYSTEM_TEMPLATE_DELETED,
	SYSTEM_CREATING_TEMPLATE,
	SYSTEM_TEMPLATE_CREATED,
	SYSTEM_FETCHING_TEMPLATE_DETAILS,
	SYSTEM_TEMPLATE_DETAILS_FETCHED,
	SYSTEM_UPDATING_TEMPLATE,
	SYSTEM_TEMPLATE_UPDATED,
	SYSTEM_TESTING_TEMPLATE,
	SYSTEM_TEMPLATE_TESTED
} from '../actions/';

const initState = { loading: true, response: null, list: [] };

export default function (state = initState, action) {
	switch (action.type) {
		case SYSTEM_FETCHING_TEMPLATES:
			return { ...state, response: null, list: [], loading: true };
		case SYSTEM_TEMPLATES_FETCHED:
			return { ...state, response: action.payload, list: action.payload.data, loading: false };

		case SYSTEM_FETCHING_TEMPLATE_DETAILS:
			return { ...state, response: null, loading: true };
		case SYSTEM_TEMPLATE_DETAILS_FETCHED:
			return { ...state, response: action.payload, loading: false };

		case SYSTEM_CREATING_TEMPLATE:
			return { ...state, response: null, creating: true };
		case SYSTEM_TEMPLATE_CREATED:
			return { ...state, response: action.payload, creating: false };

		case SYSTEM_UPDATING_TEMPLATE:
			return { ...state, response: null, updating: true };
		case SYSTEM_TEMPLATE_UPDATED:
			return { ...state, response: action.payload, updating: false };

		case SYSTEM_DELETING_TEMPLATE:
			return { ...state, deleteResponse: null, deleting: true };
		case SYSTEM_TEMPLATE_DELETED:
			return { ...state, deleteResponse: action.payload, deleting: false };

		case SYSTEM_TESTING_TEMPLATE:
			return { ...state, testResponse: null, testing: true };
		case SYSTEM_TEMPLATE_TESTED:
			return { ...state, testResponse: action.payload, testing: false };

		default:
			return state;
	}
}
