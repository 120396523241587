export const FETCHING_CUSTOMER_RISK_SCORE = "FETCHING_CUSTOMER_RISK_SCORE";
export const CUSTOMER_RISK_SCORE_FETCHED = "CUSTOMER_RISK_SCORE_FETCHED";

export const FETCHING_CUSTOMER_TRAINING_COMPLIANCE = "FETCHING_CUSTOMER_TRAINING_COMPLIANCE";
export const CUSTOMER_TRAINING_COMPLIANCE_FETCHED = "CUSTOMER_TRAINING_COMPLIANCE_FETCHED";

export const USER_LOGGING_IN = 'USER_LOGGING_IN';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const PASSWORD_RESET_DONE = "PASSWORD_RESET_DONE";
export const RESETTING_PASSWORD = "RESETTING_PASSWORD";
export const FETCHING_USERS = "FETCHING_USERS";
export const USERS_FETCHED = "USERS_FETCHED";
export const CREATING_USER = "CREATING_USER";
export const USER_CREATED = "USER_CREATED";
export const UPDATING_USER = "UPDATING_USER";
export const USER_UPDATED = "USER_UPDATED";
export const DELETING_USER = "DELETING_USER";
export const USER_DELETED = "USER_DELETED";
export const FETCHING_USER_DETAILS = "FETCHING_USER_DETAILS";
export const USER_DETAILS_FETCHED = "USER_DETAILS_FETCHED";


export const FETCHING_CUSTOMERS = 'FETCHING_CUSTOMERS';
export const CUSTOMERS_FETCHED = 'CUSTOMERS_FETCHED';
export const CREATING_CUSTOMER = 'CREATING_CUSTOMER';
export const CUSTOMER_CREATED = 'CUSTOMER_CREATED';
export const UPDATING_CUSTOMER = "UPDATING_CUSTOMER";
export const CUSTOMER_UPDATED  = "CUSTOMER_UPDATED";
export const CUSTOMER_SELECTED = 'CUSTOMER_SELECTED';
export const FETCHING_CUSTOMER_DETAILS = "FETCHING_CUSTOMER_DETAILS";
export const CUSTOMER_DETAILS_FETCHED = "CUSTOMER_DETAILS_FETCHED";
export const DELETING_CUSTOMER = "DELETING_CUSTOMER";
export const CUSTOMER_DELETED = "CUSTOMER_DELETED";

export const FETCHING_CATEGORIES = 'FETCHING_CATEGORIES';
export const CATEGORIES_FETCHED = 'CATEGORIES_FETCHED';

export const VALIDATING_FILE = 'VALIDATING_FILE';
export const FILE_VALIDATED = 'FILE_VALIDATED';
export const VALIDATING_EMPLOYEES_FILE = "VALIDATING_EMPLOYEES_FILE";
export const EMPLOYEES_FILE_VALIDATED = "EMPLOYEES_FILE_VALIDATED";

export const VALIDATING_SCORM_FILE = 'VALIDATING_SCORM_FILE';
export const SCORM_FILE_VALIDATED = 'SCORM_FILE_VALIDATED';
export const VALIDATING_COVER_FILE = 'VALIDATING_COVER_FILE';
export const COVER_FILE_VALIDATED = 'COVER_FILE_VALIDATED';

export const FETCHING_CAMPAIGNS = 'FETCHING_CAMPAIGNS';
export const CAMPAIGNS_FETCHED = 'CAMPAIGNS_FETCHED';
export const CREATING_CAMPAIGN = 'CREATING_CAMPAIGN';
export const CAMPAIGN_CREATED = 'CAMPAIGN_CREATED';

export const FETCHING_CAMPAIGNS_COUNT = 'FETCHING_CAMPAIGNS_COUNT';
export const CAMPAIGNS_COUNT_FETCHED = 'CAMPAIGNS_COUNT_FETCHED';
export const FETCHING_EMPLOYEES_COUNT = 'FETCHING_EMPLOYEES_COUNT';
export const EMPLOYEES_COUNT_FETCHED = 'EMPLOYEES_COUNT_FETCHED';
export const FETCHING_VULNERABLE_USERS_COUNT = 'FETCHING_VULNERABLE_USERS_COUNT';
export const VULNERABLE_USERS_COUNT_FETCHED = 'VULNERABLE_USERS_COUNT_FETCHED';
export const FETCHING_TEMPLATES_COUNT = 'FETCHING_TEMPLATES_COUNT';
export const TEMPLATES_COUNT_FETCHED = 'TEMPLATES_COUNT_FETCHED';

export const FETCHING_LIVE_CAMPAIGNS = 'FETCHING_LIVE_CAMPAIGNS';
export const LIVE_CAMPAIGNS_FETCHED = 'LIVE_CAMPAIGNS_FETCHED';
export const FETCHING_CAMPAIGN_WISE_REPORT = 'FETCHING_CAMPAIGN_WISE_REPORT';
export const CAMPAIGN_WISE_REPORT_FETCHED = 'CAMPAIGN_WISE_REPORT_FETCHED';

export const FETCHING_ACTIVE_EMPLOYEES = "FETCHING_ACTIVE_EMPLOYEES";
export const ACTIVE_EMPLOYEES_FETCHED = "ACTIVE_EMPLOYEES_FETCHED";
export const FETCHING_EMPLOYEES = 'FETCHING_EMPLOYEES';
export const EMPLOYEES_FETCHED = 'EMPLOYEES_FETCHED';
export const IMPORTING_EMPLOYEES = 'IMPORTING_EMPLOYEES';
export const EMPLOYEES_IMPORTED = 'EMPLOYEES_IMPORTED';
export const FETCHING_DISTINCT_BRANCH_COUNT = 'FETCHING_DISTINCT_BRANCH_COUNT';
export const FETCHING_DISTINCT_DEPARTMENT_COUNT = 'FETCHING_DISTINCT_DEPARTMENT_COUNT';
export const FETCHING_DISTINCT_DESIGNATION_COUNT = 'FETCHING_DISTINCT_DESIGNATION_COUNT';
export const DISTINCT_BRANCH_COUNT_FETCHED = 'DISTINCT_BRANCH_COUNT_FETCHED';
export const DISTINCT_DEPARTMENT_COUNT_FETCHED = 'DISTINCT_DEPARTMENT_COUNT_FETCHED';
export const DISTINCT_DESIGNATION_COUNT_FETCHED = 'DISTINCT_DESIGNATION_COUNT_FETCHED';

export const FETCHING_CAMPAIGN_USERS = 'FETCHING_CAMPAIGN_USERS';
export const CAMPAIGN_USERS_FETCHED = 'CAMPAIGN_USERS_FETCHED';
export const FETCHING_CAMPAIGN_DETAILS = 'FETCHING_CAMPAIGN_DETAILS';
export const CAMPAIGN_DETAILS_FETCHED = 'CAMPAIGN_DETAILS_FETCHED';

export const FETCHING_CAMPAIGN_ANALYTICS = 'FETCHING_CAMPAIGN_ANALYTICS';
export const CAMPAIGN_ANALYTICS_FETCHED = 'CAMPAIGN_ANALYTICS_FETCHED';

export const PROFILE_UPDATED = 'PROFILE_UPDATED';
export const UPDATING_PROFILE = 'UPDATING_PROFILE';
export const FORGETTING_PASSWORD = 'FORGETTING_PASSWORD';
export const PASSWORD_FORGOT = 'PASSWORD_FORGOT';

export const DELETING_CAMPAIGN = 'DELETING_CAMPAIGN';
export const CAMPAIGN_DELETED = 'CAMPAIGN_DELETED';

export const STOPPING_CAMPAIGN = "STOPPING_CAMPAIGN";
export const CAMPAIGN_STOPPED = "CAMPAIGN_STOPPED";

export const SHOW_CONFIRM_BOX = 'SHOW_CONFIRM_BOX';
export const CLOSE_CONFIRM_BOX = 'CLOSE_CONFIRM_BOX';


export const DELETING_EMPLOYEE = 'DELETING_EMPLOYEE';
export const EMPLOYEE_DELETED = 'EMPLOYEE_DELETED';
export const UPDATING_EMPLOYEE = "UPDATING_EMPLOYEE";
export const EMPLOYEE_UPDATED = "EMPLOYEE_UPDATED";
export const BULK_EMPLOYEE_DELETED = "BULK_EMPLOYEE_DELETED";
export const DELETING_BULK_EMPLOYEE = "DELETING_BULK_EMPLOYEE";
export const ACTIVATING_BULK_EMPLOYEE = "ACTIVATING_BULK_EMPLOYEE";
export const BULK_EMPLOYEE_ACTIVATED = "BULK_EMPLOYEE_ACTIVATED";
export const	DEACTIVATING_BULK_EMPLOYEE = "DEACTIVATING_BULK_EMPLOYEE";
export const BULK_EMPLOYEE_DEACTIVATED = "BULK_EMPLOYEE_DEACTIVATED";

export const FETCHING_TEMPLATES = 'FETCHING_TEMPLATES';
export const TEMPLATES_FETCHED = 'TEMPLATES_FETCHED';
export const FETCHING_TEMPLATE_DETAILS = 'FETCHING_TEMPLATE_DETAILS';
export const TEMPLATE_DETAILS_FETCHED = 'TEMPLATE_DETAILS_FETCHED';
export const UPDATING_TEMPLATE = "UPDATING_TEMPLATE";
export const TEMPLATE_UPDATED = "TEMPLATE_UPDATED";
export const TEMPLATE_DELETED = "TEMPLATE_DELETED";
export const DELETING_TEMPLATE = "DELETING_TEMPLATE";
export const CREATING_TEMPLATE = "CREATING_TEMPLATE";
export const TEMPLATE_CREATED = "TEMPLATE_CREATED";
export const TESTING_TEMPLATE = "TESTING_TEMPLATE";
export const TEMPLATE_TESTED = "TEMPLATE_TESTED";

export const FETCHING_LANDING_PAGES = 'FETCHING_LANDING_PAGES';
export const LANDING_PAGES_FETCHED = 'LANDING_PAGES_FETCHED';
export const FETCHING_LANDING_PAGE_DETAILS = 'FETCHING_LANDING_PAGE_DETAILS';
export const LANDING_PAGE_DETAILS_FETCHED = 'LANDING_PAGE_DETAILS_FETCHED';
export const UPDATING_LANDING_PAGE = "UPDATING_LANDING_PAGE";
export const LANDING_PAGE_UPDATED = "LANDING_PAGE_UPDATED";
export const LANDING_PAGE_DELETED = "LANDING_PAGE_DELETED";
export const DELETING_LANDING_PAGE = "DELETING_LANDING_PAGE";
export const CREATING_LANDING_PAGE = "CREATING_LANDING_PAGE";
export const LANDING_PAGE_CREATED = "LANDING_PAGE_CREATED";

export const FETCHING_TRAINING_MODULES = 'FETCHING_TRAINING_MODULES';
export const TRAINING_MODULES_FETCHED = 'TRAINING_MODULES_FETCHED';
export const FETCHING_TRAINING_MODULE_DETAILS = 'FETCHING_TRAINING_MODULE_DETAILS';
export const TRAINING_MODULE_DETAILS_FETCHED = 'TRAINING_MODULE_DETAILS_FETCHED';
export const UPDATING_TRAINING_MODULE = "UPDATING_TRAINING_MODULE";
export const TRAINING_MODULE_UPDATED = "TRAINING_MODULE_UPDATED";
export const TRAINING_MODULE_DELETED = "TRAINING_MODULE_DELETED";
export const DELETING_TRAINING_MODULE = "DELETING_TRAINING_MODULE";
export const CREATING_TRAINING_MODULE = "CREATING_TRAINING_MODULE";
export const TRAINING_MODULE_CREATED = "TRAINING_MODULE_CREATED";
export const FETCHING_TRAINING_MODULES_COUNT = "FETCHING_TRAINING_MODULES_COUNT";
export const TRAINING_MODULES_COUNT_FETCHED = "TRAINING_MODULES_COUNT_FETCHED";

export const UPLAODING_PROFILE_IMAGE = "UPLAODING_PROFILE_IMAGE";
export const PROFILE_IMAGE_UPLOADED = "PROFILE_IMAGE_UPLOADED";

export const SENDING_REMINDER = "SENDING_REMINDER";
export const REMINDER_SENT = "REMINDER_SENT";
export const SENDING_ESCALATION = "SENDING_ESCALATION";
export const ESCALATION_SENT = "ESCALATION_SENT";
export const FETCHING_REMINDERS_COUNT = "FETCHING_REMINDERS_COUNT";
export const REMINDERS_COUNT_FETCHED = "REMINDERS_COUNT_FETCHED";
export const FETCHING_ESCALATIONS_COUNT = "FETCHING_ESCALATIONS_COUNT";
export const ESCALATIONS_COUNT_FETCHED = "ESCALATIONS_COUNT_FETCHED";


export const FETCHING_GROUPS = "FETCHING_GROUPS";
export const GROUPS_FETCHED = "GROUPS_FETCHED";
export const CREATING_GROUP = "CREATING_GROUP";
export const GROUP_CREATED = "GROUP_CREATED";

export const UPLOADING_GROUP_EMPLOYEES = "UPLOADING_GROUP_EMPLOYEES";
export const GROUP_EMPLOYEES_UPLOAD = "GROUP_EMPLOYEES_UPLOAD";
export const DELETING_GROUP_EMPLOYEES = "DELETING_GROUP_EMPLOYEES";
export const GROUP_EMPLOYEES_DELETED = "GROUP_EMPLOYEES_DELETED";
export const DELETING_GROUP = "DELETING_GROUP";
export const GROUP_DELETED = "GROUP_DELETD";
export const CREATING_BULK_GROUPS = "CREATING_BULK_GROUPS";
export const BULK_GROUPS_CREATED = "BULK_GROUPS_CREATED";

export const SYSTEM_TEMPLATES_FETCHED = "SYSTEM_TEMPLATES_FETCHED";
export const SYSTEM_FETCHING_TEMPLATES = "SYSTEM_FETCHING_TEMPLATES";
export const SYSTEM_TEMPLATE_DELETED = "SYSTEM_TEMPLATE_DELETED";
export const SYSTEM_DELETING_TEMPLATE = "SYSTEM_DELETING_TEMPLATE";
export const SYSTEM_CREATING_TEMPLATE = "SYSTEM_CREATING_TEMPLATE";
export const SYSTEM_TEMPLATE_CREATED = "SYSTEM_TEMPLATE_CREATED";
export const SYSTEM_TEMPLATE_DETAILS_FETCHED = "SYSTEM_TEMPLATE_DETAILS_FETCHED"; 
export const SYSTEM_FETCHING_TEMPLATE_DETAILS = "SYSTEM_FETCHING_TEMPLATE_DETAILS";
export const SYSTEM_UPDATING_TEMPLATE = "SYSTEM_UPDATING_TEMPLATE";
export const SYSTEM_TEMPLATE_UPDATED = "SYSTEM_TEMPLATE_UPDATED";
export const SYSTEM_TEMPLATE_TESTED = "SYSTEM_TEMPLATE_TESTED";
export const SYSTEM_TESTING_TEMPLATE = "SYSTEM_TESTING_TEMPLATE";

export const SMTP_UPDATED = "SMTP_UPDATED";
export const UPDATING_SMTP = "UPDATING_SMTP";

export const FETCHING_CAMPAIGN_REMINDERS = "FETCHING_CAMPAIGN_REMINDERS";
export const CAMPAIGN_REMINDERS_FETCHED = "CAMPAIGN_REMINDERS_FETCHED";
export const FETCHING_REMINDER_TARGETS = "FETCHING_REMINDER_TARGETS";
export const REMINDER_TARGETS_FETCHED = "REMINDER_TARGETS_FETCHED";

export const FETCHING_PHISHING_REPORTS = "FETCHING_PHISHING_REPORTS";
export const PHISHING_REPORTS_FETCHED = "PHISHING_REPORTS_FETCHED";


export const POSTERS_FETCHED = "POSTERS_FETCHED";
export const FETCHING_POSTERS = "FETCHING_POSTERS";
export const POSTER_DETAILS_FETCHED = "POSTER_DETAILS_FETCHED";
export const FETCHING_POSTER_DETAILS = "FETCHING_POSTER_DETAILS";

export const POSTER_CATEGORIES_FETCHED = "POSTER_CATEGORIES_FETCHED";
export const FETCHING_POSTER_CATEGORIES = "FETCHING_POSTER_CATEGORIES";

export const FETCHING_TRAINING_LANGUAGES_COUNT = "FETCHING_TRAINING_LANGUAGES_COUNT";
export const TRAINING_LANGUAGES_COUNT_FETCHED = "TRAINING_LANGUAGES_COUNT_FETCHED";


export const FETCHING_SCAN_REPORT = "FETCHING_SCAN_REPORT";
export const SCAN_REPORT_FETCHED = "SCAN_REPORT_FETCHED";

export const FETCHING_SCIM_CONFIG = "FETCHING_SCIM_CONFIG";
export const SCIM_CONFIG_FETCHED = "SCIM_CONFIG_FETCHED";