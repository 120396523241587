import {
	TEMPLATES_FETCHED,
	FETCHING_TEMPLATES,
	DELETING_TEMPLATE,
	TEMPLATE_DELETED,
	CREATING_TEMPLATE,
	TEMPLATE_CREATED,
	FETCHING_TEMPLATE_DETAILS,
	TEMPLATE_DETAILS_FETCHED,
	UPDATING_TEMPLATE,
	TEMPLATE_UPDATED,
	TESTING_TEMPLATE,
	TEMPLATE_TESTED,
} from '../actions/';

const initState = { loading: true, response: null, list: [] };

export default function (state = initState, action) {
	switch (action.type) {
		case FETCHING_TEMPLATES:
			return { ...state, response: null, list: [], loading: true };
		case TEMPLATES_FETCHED:
			return { ...state, response: action.payload, list: action.payload.data, loading: false };

		case FETCHING_TEMPLATE_DETAILS:
			return { ...state, response: null, loading: true };
		case TEMPLATE_DETAILS_FETCHED:
			return { ...state, response: action.payload, loading: false };

		case CREATING_TEMPLATE:
			return { ...state, response: null, creating: true };
		case TEMPLATE_CREATED:
			return { ...state, response: action.payload, creating: false };

		case UPDATING_TEMPLATE:
			return { ...state, response: null, updating: true };
		case TEMPLATE_UPDATED:
			return { ...state, response: action.payload, updating: false };

		case DELETING_TEMPLATE:
			return { ...state, deleteResponse: null, deleting: true };
		case TEMPLATE_DELETED:
			return { ...state, deleteResponse: action.payload, deleting: false };

		case TESTING_TEMPLATE:
			return { ...state, testResponse: null, testing: true };
		case TEMPLATE_TESTED:
			return { ...state, testResponse: action.payload, testing: false };

		default:
			return state;
	}
}
