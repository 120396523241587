import { POSTER_CATEGORIES_FETCHED, FETCHING_POSTER_CATEGORIES } from '../actions/';

const initState = { loading: true, response: null, list: [] };

export default function (state = initState, action) {
	switch (action.type) {
    case FETCHING_POSTER_CATEGORIES:
      return {...state, response: null, list: [], loading: true}
		case POSTER_CATEGORIES_FETCHED:
			return { ...state, response: action.payload, list: action.payload.data,  loading: false };
		default:
			return state;
	}
}
