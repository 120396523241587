import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { CSpinner } from '@coreui/react-pro';
import { FAVICON_URL } from './utils/constant';
import './scss/style.scss';

// import ThemeFactory from './utils/theme_factory';
// const theme = process.env.REACT_APP_THEME;
// ThemeFactory.importTheme(theme);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

const Login = React.lazy(() => import('./views/login/Login'));
const ForgotPassword = React.lazy(() => import('./views/forgotPassword/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./views/resetPassword/ResetPassword'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const App = () => {
	useEffect(() => {
		let link = document.querySelector("link[rel~='icon']");
		if (!link) {
			link = document.createElement('link');
			link.rel = 'icon';
			document.getElementsByTagName('head')[0].appendChild(link);
		}
		link.href = FAVICON_URL;
	}, []);

	return (
		<BrowserRouter>
			<Suspense fallback={<CSpinner color='primary' />}>
				<Routes>
					{/* public routes */}
					<Route path='/login' exact={true} name='Login' element={<Login />} />
					<Route path='/first-login' exact={true} name='Login' element={<Login />} />
					<Route path='/forgot/password' exact={true} name='Forgot Password' element={<ForgotPassword />} />
					<Route path='/reset/password' exact={true} name='Reset Password' element={<ResetPassword />} />
					<Route path='/reset/password/:resetCode' exact={true} name='Reset Password' element={<ResetPassword />} />
					<Route exact path='/404' name='Page 404' element={<Page404 />} />
					<Route exact path='/500' name='Page 500' element={<Page500 />} />

					{/* private routes */}
					<Route path='*' name='Home' element={<DefaultLayout />} />
					<Route path='/' element={<Navigate to='/login' replace />} />
				</Routes>
			</Suspense>
		</BrowserRouter>
	);
};

export default App;
