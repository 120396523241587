import {
	ACTIVATING_BULK_EMPLOYEE,
	ACTIVE_EMPLOYEES_FETCHED,
	BULK_EMPLOYEE_ACTIVATED,
	BULK_EMPLOYEE_DEACTIVATED,
	BULK_EMPLOYEE_DELETED,
	DEACTIVATING_BULK_EMPLOYEE,
	DELETING_BULK_EMPLOYEE,
	DELETING_EMPLOYEE,
	DISTINCT_BRANCH_COUNT_FETCHED,
	DISTINCT_DEPARTMENT_COUNT_FETCHED,
	DISTINCT_DESIGNATION_COUNT_FETCHED,
	EMPLOYEES_FETCHED,
	EMPLOYEES_FILE_VALIDATED,
	EMPLOYEES_IMPORTED,
	EMPLOYEE_DELETED,
	EMPLOYEE_UPDATED,
	FETCHING_ACTIVE_EMPLOYEES,
	FETCHING_EMPLOYEES,
	IMPORTING_EMPLOYEES,
	UPDATING_EMPLOYEE,
	VALIDATING_EMPLOYEES_FILE,
} from 'src/actions';

const initState = {
	activeEmployeeCount: 0,
	distinctBranchCount: 0,
	distinctDepartmentCount: 0,
	distinctDesignationCount: 0,
	response: null,
	list: [],
	loading: false,
	importing: false,
	importResponse: null,
};

export default function (state = initState, action) {
	switch (action.type) {
		case VALIDATING_EMPLOYEES_FILE:
			return { ...state, uploadResponse: null, uploading: true };
		case EMPLOYEES_FILE_VALIDATED:
			return { ...state, uploadResponse: action.payload, uploading: false };
		case DISTINCT_BRANCH_COUNT_FETCHED:
			return { ...state, response: action.payload, distinctBranchCount: action.payload.data, loading: false };
		case DISTINCT_DEPARTMENT_COUNT_FETCHED:
			return { ...state, response: action.payload, distinctDepartmentCount: action.payload.data, loading: false };
		case DISTINCT_DESIGNATION_COUNT_FETCHED:
			return { ...state, response: action.payload, distinctDesignationCount: action.payload.data, loading: false };
		case FETCHING_EMPLOYEES:
			return { ...state, response: null, loading: true };
		case EMPLOYEES_FETCHED:
			return { ...state, response: action.payload, loading: false };
		case FETCHING_ACTIVE_EMPLOYEES:
			return { ...state, list: [], loading: true };
		case ACTIVE_EMPLOYEES_FETCHED:
			return { ...state, response: action.payload, list: action.payload.data || [], activeEmployeeCount: (action.payload.data || []).length, loading: false };
		case IMPORTING_EMPLOYEES:
			return { ...state, importing: true };
		case EMPLOYEES_IMPORTED:
			return { ...state, importResponse: action.payload, importing: false };

		case DELETING_EMPLOYEE:
			return { ...state, deleteResponse: null, deleting: true };
		case EMPLOYEE_DELETED:
			return { ...state, deleteResponse: action.payload, deleting: false };

		case DELETING_BULK_EMPLOYEE:
			return { ...state, deleteResponse: null, deleting: true };
		case BULK_EMPLOYEE_DELETED:
			return { ...state, deleteResponse: action.payload, deleting: false };

		case ACTIVATING_BULK_EMPLOYEE:
			return { ...state, activateResponse: null, activating: true };
		case BULK_EMPLOYEE_ACTIVATED:
			return { ...state, activateResponse: action.payload, activating: false };

		case DEACTIVATING_BULK_EMPLOYEE:
			return { ...state, deactivateResponse: null, deactivating: true };
		case BULK_EMPLOYEE_DEACTIVATED:
			return { ...state, deactivateResponse: action.payload, deactivating: false };

		case UPDATING_EMPLOYEE:
			return { ...state, updateResponse: null, updating: true };
		case EMPLOYEE_UPDATED:
			return { ...state, updateResponse: action.payload, deleting: false };

		default:
			return state;
	}
}
