import { CREATING_TRAINING_MODULE, DELETING_TRAINING_MODULE, FETCHING_TRAINING_MODULES, FETCHING_TRAINING_MODULE_DETAILS, TRAINING_MODULES_FETCHED, TRAINING_MODULE_CREATED, TRAINING_MODULE_DELETED, TRAINING_MODULE_DETAILS_FETCHED, TRAINING_MODULE_UPDATED, UPDATING_TRAINING_MODULE } from "src/actions";

const initState = { loading: true, response: null, list: [] };

export default function (state = initState, action) {
	switch (action.type) {
		case FETCHING_TRAINING_MODULES:
			return { ...state, response: null, list: [], loading: true };
		case TRAINING_MODULES_FETCHED:
			return { ...state, response: action.payload, list: action.payload.data, loading: false };

		case FETCHING_TRAINING_MODULE_DETAILS:
			return { ...state, response: null, loading: true };
		case TRAINING_MODULE_DETAILS_FETCHED:
			return { ...state, response: action.payload, loading: false };

		case CREATING_TRAINING_MODULE:
			return { ...state, response: null, creating: true };
		case TRAINING_MODULE_CREATED:
			return { ...state, response: action.payload, creating: false };

		case UPDATING_TRAINING_MODULE:
			return { ...state, response: null, updating: true };
		case TRAINING_MODULE_UPDATED:
			return { ...state, response: action.payload, updating: false };

		case DELETING_TRAINING_MODULE:
			return { ...state, deleteResponse: null, deleting: true };
		case TRAINING_MODULE_DELETED:
			return { ...state, deleteResponse: action.payload, deleting: false };
		default:
			return state;
	}
}
