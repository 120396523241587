import { CLOSE_CONFIRM_BOX, SHOW_CONFIRM_BOX } from 'src/actions';

const initState = { visible: false, confirmText: null, onConfirm: null };

export default function (state = initState, action) {
	switch (action.type) {
		case SHOW_CONFIRM_BOX:
			return { ...state, visible: true, ...action.payload };
		case CLOSE_CONFIRM_BOX:
			return { visible: false, confirmText: null, onConfirm: null };
		default:
			return state;
	}
}
