import {
	CREATING_CUSTOMER,
	CUSTOMERS_FETCHED,
	CUSTOMER_CREATED,
	CUSTOMER_DELETED,
	CUSTOMER_DETAILS_FETCHED,
	CUSTOMER_SELECTED,
	CUSTOMER_UPDATED,
	DELETING_CUSTOMER,
	FETCHING_CUSTOMERS,
	FETCHING_CUSTOMER_DETAILS,
	FETCHING_SCIM_CONFIG,
	SCIM_CONFIG_FETCHED,
	SMTP_UPDATED,
	UPDATING_CUSTOMER,
	UPDATING_SMTP,
} from '../actions/';

const initState = { loading: true, response: null, list: [], currentCustomer: {} };

export default function (state = initState, action) {
	switch (action.type) {
		case FETCHING_CUSTOMERS:
			return { ...state, response: null, list: [], loading: true };
		case CUSTOMERS_FETCHED:
			return { ...state, response: action.payload, list: action.payload.data, loading: false };
		case CREATING_CUSTOMER:
			return { ...state, response: null, loading: true };
		case CUSTOMER_CREATED:
			return { ...state, response: action.payload, loading: false };
		case CUSTOMER_SELECTED:
			return { ...state, currentCustomer: action.payload };

		case FETCHING_CUSTOMER_DETAILS:
			return { ...state, customerResponse: null, fetching: true };
		case CUSTOMER_DETAILS_FETCHED:
			return { ...state, customerResponse: action.payload, fetching: false };

		case UPDATING_CUSTOMER:
			return { ...state, response: null, updating: true };
		case CUSTOMER_UPDATED:
			return { ...state, response: action.payload, updating: false };

		case DELETING_CUSTOMER:
			return { ...state, deleteResponse: null, deleting: true };
		case CUSTOMER_DELETED:
			return { ...state, deleteResponse: action.payload, deleting: false };

		case UPDATING_SMTP:
			return { ...state, smtpResponse: null, updating: true };
		case SMTP_UPDATED:
			return { ...state, smtpResponse: action.payload, updating: false };

		case FETCHING_SCIM_CONFIG:
			return { ...state, scimResponse: null, loading: true };
		case SCIM_CONFIG_FETCHED:
			return { ...state, scimResponse: action.payload, loading: false };
		default:
			return state;
	}
}
