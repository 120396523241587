import {
	CREATING_USER,
	DELETING_USER,
	FETCHING_USERS,
	FETCHING_USER_DETAILS,
	FORGETTING_PASSWORD,
	PASSWORD_FORGOT,
	PASSWORD_RESET_DONE,
	PROFILE_IMAGE_UPLOADED,
	PROFILE_UPDATED,
	RESETTING_PASSWORD,
	UPDATING_PROFILE,
	UPDATING_USER,
	UPLAODING_PROFILE_IMAGE,
	USERS_FETCHED,
	USER_CREATED,
	USER_DELETED,
	USER_DETAILS_FETCHED,
	USER_LOGGED_IN,
	USER_LOGGED_OUT,
	USER_LOGGING_IN,
	USER_UPDATED,
} from 'src/actions';

const initState = { loading: true, response: null };

export default function (state = initState, action) {
	switch (action.type) {
		case USER_LOGGING_IN:
			return { ...state, response: null, userData: null, loading: true };
		case USER_LOGGED_IN:
			let response = action.payload;
			return { ...state, response, userData: response.data, loading: false };

		case RESETTING_PASSWORD:
			return { ...state, response: null, userData: null, loading: true };
		case PASSWORD_RESET_DONE:
			return { ...state, response: action.payload, userData: action.payload.data, loading: false };

		case USER_LOGGED_OUT:
			return { ...state, user: null, loading: false };
		case UPDATING_PROFILE:
			return { ...state, response: null, loading: true };
		case PROFILE_UPDATED:
			const userData = { ...state.userData };
			if (!action.payload.error) {
				userData.user = action.payload.data;
			}
			return { ...state, response: action.payload, loading: false, userData };
		case FORGETTING_PASSWORD:
			return { ...state, response: null, loading: true };
		case PASSWORD_FORGOT:
			return { ...state, response: action.payload, loading: false };

		case UPLAODING_PROFILE_IMAGE:
			return { ...state, response: null, uploading: true };
		case PROFILE_IMAGE_UPLOADED:
			return { ...state, response: action.payload, uploading: false };

		case FETCHING_USERS:
			return { ...state, response: null, fetching: true };
		case USERS_FETCHED:
			return { ...state, response: action.payload, fetching: false };

		case FETCHING_USER_DETAILS:
			return { ...state, response: null, fetching: true };
		case USER_DETAILS_FETCHED:
			return { ...state, response: action.payload, fetching: false };

		case CREATING_USER:
			return { ...state, response: null, creating: true };
		case USER_CREATED:
			return { ...state, response: action.payload, creating: false };

		case UPDATING_USER:
			return { ...state, response: null, updating: true };
		case USER_UPDATED:
			return { ...state, response: action.payload, updating: false };

		case DELETING_USER:
			return { ...state, deleteResponse: null, deleting: true };
		case USER_DELETED:
			return { ...state, deleteResponse: action.payload, deleting: false };

		default:
			return state;
	}
}
