import { FETCHING_PHISHING_REPORTS, FETCHING_SCAN_REPORT, PHISHING_REPORTS_FETCHED, SCAN_REPORT_FETCHED } from '../actions/';

const initState = { loading: true, response: null, list: [], scanReports: [] };

export default function (state = initState, action) {
	switch (action.type) {
		case FETCHING_PHISHING_REPORTS:
			return { ...state, response: null, list: [], loading: true };
		case PHISHING_REPORTS_FETCHED:
			return { ...state, response: action.payload, list: action.payload.data, loading: false };

		case FETCHING_SCAN_REPORT:
			return { ...state, response: null, scanReports: [], fetchingScanReport: true };
		case SCAN_REPORT_FETCHED:
			return { ...state, response: action.payload, scanReports: action.payload.data, fetchingScanReport: false };

		default:
			return state;
	}
}
