import { CAMPAIGN_REMINDERS_FETCHED, FETCHING_CAMPAIGN_REMINDERS, FETCHING_REMINDER_TARGETS, REMINDER_TARGETS_FETCHED } from 'src/actions';

const initState = { loading: true, response: null, list: [], targets: [] };

export default function (state = initState, action) {
	switch (action.type) {
		case FETCHING_CAMPAIGN_REMINDERS:
			return { ...state, response: null, list: [], loading: true };
		case CAMPAIGN_REMINDERS_FETCHED:
			return { ...state, response: action.payload, list: action.payload.data, loading: false };

		case FETCHING_REMINDER_TARGETS:
			return { ...state, response: null, targets: [], loading: true };
		case REMINDER_TARGETS_FETCHED:
			return { ...state, response: action.payload, targets: action.payload.data, loading: false };

		default:
			return state;
	}
}
