import 'react-app-polyfill/stable';
import 'core-js';
import React from 'react';

import ReduxToastr from 'react-redux-toastr';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import configureStore from './store';
import ConfirmModal from './components/utils/ConfirmModal';

const store = configureStore();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	<Provider store={store}>
		<ConfirmModal />
		<ReduxToastr
			timeOut={10000}
			newestOnTop={false}
			preventDuplicates
			position='top-right'
			getState={(state) => state.toastr} // This is the default
			transitionIn='fadeIn'
			transitionOut='fadeOut'
			progressBar
			closeOnToastrClick
		/>

		<App />
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
