import {
	CAMPAIGNS_COUNT_FETCHED,
	CAMPAIGN_WISE_REPORT_FETCHED,
	CUSTOMER_RISK_SCORE_FETCHED,
	CUSTOMER_TRAINING_COMPLIANCE_FETCHED,
	EMPLOYEES_COUNT_FETCHED,
	FETCHING_LIVE_CAMPAIGNS,
	LIVE_CAMPAIGNS_FETCHED,
	TEMPLATES_COUNT_FETCHED,
	TRAINING_LANGUAGES_COUNT_FETCHED,
	TRAINING_MODULES_COUNT_FETCHED,
	VULNERABLE_USERS_COUNT_FETCHED,
} from 'src/actions';

const initState = {
	riskScore: 0,
	activeCampaignsCount: 0,
	campaignsCount: { active: 0 },
	employeesCount: 0,
	trainingLangCount: 0,
	vulnerableUsersCount: 0,
	trainingModulesCount: 0,
	templatesCount: 0,
	response: null,
	campaigns: [],
	campaignsReport: [],
	loading: false,
};

export default function (state = initState, action) {
	switch (action.type) {
		case CAMPAIGNS_COUNT_FETCHED:
			return { ...state, response: action.payload, campaignsCount: { ...state.campaignsCount, ...action.payload.data }, loading: false };
		case EMPLOYEES_COUNT_FETCHED:
			return { ...state, response: action.payload, employeesCount: action.payload.data, loading: false };
		case TRAINING_LANGUAGES_COUNT_FETCHED:
			return { ...state, response: action.payload, trainingLangCount: action.payload.data, loading: false };
		case VULNERABLE_USERS_COUNT_FETCHED:
			return { ...state, response: action.payload, vulnerableUsersCount: action.payload.data, loading: false };
		case TEMPLATES_COUNT_FETCHED:
			return { ...state, response: action.payload, templatesCount: action.payload.data, loading: false };
		case FETCHING_LIVE_CAMPAIGNS:
			return { ...state, campaigns: [], loading: true };
		case LIVE_CAMPAIGNS_FETCHED:
			return { ...state, response: action.payload, campaigns: action.payload.data || [], loading: false };
		case CAMPAIGN_WISE_REPORT_FETCHED:
			return { ...state, response: action.payload, campaignsReport: action.payload.data || [], loading: false };

		case TRAINING_MODULES_COUNT_FETCHED:
			return { ...state, response: action.payload, trainingModulesCount: action.payload.data, loading: false };

		case CUSTOMER_RISK_SCORE_FETCHED:
			return { ...state, response: action.payload, riskScore: action.payload.data, loading: false };

		case CUSTOMER_TRAINING_COMPLIANCE_FETCHED:
			return { ...state, response: action.payload, trainingCompliance: action.payload.data, loading: false };

		default:
			return state;
	}
}
