import {
	LANDING_PAGES_FETCHED,
	FETCHING_LANDING_PAGES,
	DELETING_LANDING_PAGE,
	LANDING_PAGE_DELETED,
	CREATING_LANDING_PAGE,
	LANDING_PAGE_CREATED,
	FETCHING_LANDING_PAGE_DETAILS,
	LANDING_PAGE_DETAILS_FETCHED,
	UPDATING_LANDING_PAGE,
	LANDING_PAGE_UPDATED,
} from '../actions/';

const initState = { loading: true, response: null, list: [] };

export default function (state = initState, action) {
	switch (action.type) {
		case FETCHING_LANDING_PAGES:
			return { ...state, response: null, list: [], loading: true };
		case LANDING_PAGES_FETCHED:
			return { ...state, response: action.payload, list: action.payload.data, loading: false };

		case FETCHING_LANDING_PAGE_DETAILS:
			return { ...state, response: null, loading: true };
		case LANDING_PAGE_DETAILS_FETCHED:
			return { ...state, response: action.payload, loading: false };

		case CREATING_LANDING_PAGE:
			return { ...state, response: null, creating: true };
		case LANDING_PAGE_CREATED:
			return { ...state, response: action.payload, creating: false };

		case UPDATING_LANDING_PAGE:
			return { ...state, response: null, updating: true };
		case LANDING_PAGE_UPDATED:
			return { ...state, response: action.payload, updating: false };

		case DELETING_LANDING_PAGE:
			return { ...state, deleteResponse: null, deleting: true };
		case LANDING_PAGE_DELETED:
			return { ...state, deleteResponse: action.payload, deleting: false };
		default:
			return state;
	}
}
