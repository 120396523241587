import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react-pro";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { confirmBox } from "src/actions/confirmBox";

const ConfirmModal = () => {
  const dispatch = useDispatch();
  const confirmText = useSelector(state=> state.confirmBox?.confirmText);
  const onConfirm = useSelector(state=> state.confirmBox?.onConfirm);
  const visible = useSelector(state=> state.confirmBox?.visible);

	return (
		<>
			<CModal visible={visible} onClose={() => confirmBox.close(dispatch)} aria-labelledby='confirmModal'>
				<CModalHeader onClose={() => confirmBox.close(dispatch)}>
					<CModalTitle id='confirmModal'>Are you sure?</CModalTitle>
				</CModalHeader>
				<CModalBody>
					<p>{confirmText}</p>
				</CModalBody>
				<CModalFooter>
					<CButton color='secondary' style={{"color":"white"}} onClick={() => confirmBox.close(dispatch)}>
						Cancel
					</CButton>
					<CButton color='primary' onClick={onConfirm}>Yes</CButton>
				</CModalFooter>
			</CModal>
		</>
	);
};

export default ConfirmModal;