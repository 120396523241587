import {
	POSTERS_FETCHED,
	FETCHING_POSTERS,
	FETCHING_POSTER_DETAILS,
	POSTER_DETAILS_FETCHED,
} from '../actions/';

const initState = { loading: true, response: null, list: [] };

export default function (state = initState, action) {
	switch (action.type) {
		case FETCHING_POSTERS:
			return { ...state, response: null, list: [], loading: true };
		case POSTERS_FETCHED:
			return { ...state, response: action.payload, list: action.payload.data, loading: false };

		case FETCHING_POSTER_DETAILS:
			return { ...state, response: null, loading: true };
		case POSTER_DETAILS_FETCHED:
			return { ...state, response: action.payload, loading: false };


		default:
			return state;
	}
}
