import {
	BULK_GROUPS_CREATED,
	CREATING_BULK_GROUPS,
	CREATING_GROUP,
	DELETING_GROUP,
	DELETING_GROUP_EMPLOYEES,
	FETCHING_GROUPS,
	GROUPS_FETCHED,
	GROUP_CREATED,
	GROUP_DELETED,
	GROUP_EMPLOYEES_DELETED,
	GROUP_EMPLOYEES_UPLOAD,
	UPLOADING_GROUP_EMPLOYEES,
} from 'src/actions';

const initState = {
	list: [],
	response: null,
	loading: false,
};

export default function (state = initState, action) {
	switch (action.type) {
		case FETCHING_GROUPS:
			return { ...state, response: null, list: [], loading: true };
		case GROUPS_FETCHED:
			return { ...state, response: action.payload, list: action.payload.data || [], loading: false };

		case CREATING_GROUP:
			return { ...state, response: null, creating: true };
		case GROUP_CREATED:
			return { ...state, response: action.payload, creating: false };

		case CREATING_BULK_GROUPS:
			return { ...state, response: null, creating: true };
		case BULK_GROUPS_CREATED:
			return { ...state, response: action.payload, creating: false };

		case UPLOADING_GROUP_EMPLOYEES:
			return { ...state, response: null, uploading: true };
		case GROUP_EMPLOYEES_UPLOAD:
			return { ...state, response: action.payload, uploading: false };

		case DELETING_GROUP_EMPLOYEES:
			return { ...state, deleteResponse: null, deleting: true };
		case GROUP_EMPLOYEES_DELETED:
			return { ...state, deleteResponse: action.payload, deleting: false };

		case DELETING_GROUP:
			return { ...state, deleteGroupResponse: null, deletingGroup: true };
		case GROUP_DELETED:
			return { ...state, deleteGroupResponse: action.payload, deletingGroup: false };

		default:
			return state;
	}
}
