import { SHOW_CONFIRM_BOX, CLOSE_CONFIRM_BOX } from '../actions';

const show = async (options, dispatch) => {
  
	dispatch({
		type: SHOW_CONFIRM_BOX,
		payload: {...options, onConfirm: ()=>{
      options.onConfirm && options.onConfirm();
      close(dispatch);
    }},
	});
};

const close = async (dispatch) => {
	dispatch({
		type: CLOSE_CONFIRM_BOX
	});
};


export const confirmBox = {
  show,
  close
}