const initState = {
	sidebarShow: true,
	asideShow: false,
	theme: 'default',
};

export default function (state = initState, action) {
	switch (action.type) {
		case 'set':
			return { ...state, ...action };
		default:
			return state;
	}
}
