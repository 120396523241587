import {
	CAMPAIGNS_FETCHED,
	CAMPAIGN_ANALYTICS_FETCHED,
	CAMPAIGN_CREATED,
	CAMPAIGN_DELETED,
	CAMPAIGN_DETAILS_FETCHED,
	CAMPAIGN_STOPPED,
	CAMPAIGN_USERS_FETCHED,
	CREATING_CAMPAIGN,
	DELETING_CAMPAIGN,
	ESCALATIONS_COUNT_FETCHED,
	ESCALATION_SENT,
	FETCHING_CAMPAIGNS,
	FETCHING_CAMPAIGN_DETAILS,
	FETCHING_CAMPAIGN_USERS,
	FETCHING_ESCALATIONS_COUNT,
	FETCHING_REMINDERS_COUNT,
	FILE_VALIDATED,
	REMINDERS_COUNT_FETCHED,
	REMINDER_SENT,
	SENDING_ESCALATION,
	SENDING_REMINDER,
	STOPPING_CAMPAIGN,
	VALIDATING_FILE,
} from '../actions/';

const initState = {
	loading: true,
	response: null,
	list: [],
	users: [],
	campaignDetails: null,
	fetchingCampaignDetails: true,
	analytics: { branch: null, department: null, designation: null },
	reminders: 0,
	escalations: 0,
};

export default function (state = initState, action) {
	switch (action.type) {
		case VALIDATING_FILE:
			return { ...state, targetsResponse: null, loading: true };
		case FILE_VALIDATED:
			return { ...state, targetsResponse: action.payload, targets: action.payload.data, loading: false };
		case CREATING_CAMPAIGN:
			return { ...state, response: null, loading: true };
		case CAMPAIGN_CREATED:
			return { ...state, response: action.payload, loading: false };
		case FETCHING_CAMPAIGNS:
			return { ...state, response: null, list: [], loading: true };
		case CAMPAIGNS_FETCHED:
			return { ...state, response: action.payload, list: action.payload.data || [], loading: false };
		case FETCHING_CAMPAIGN_USERS:
			return { ...state, response: null, users: [], loading: true };
		case CAMPAIGN_USERS_FETCHED:
			return { ...state, response: action.payload, users: action.payload.data || [], loading: false };
		case FETCHING_CAMPAIGN_DETAILS:
			return { ...state, response: null, campaignDetails: {}, fetchingCampaignDetails: true };
		case CAMPAIGN_DETAILS_FETCHED:
			return { ...state, response: action.payload, campaignDetails: action.payload.data, fetchingCampaignDetails: false };

		case CAMPAIGN_ANALYTICS_FETCHED:
			return { ...state, response: action.payload, analytics: { ...state.analytics, ...(action.payload.data || {}) } };

		case DELETING_CAMPAIGN:
			return { ...state, response: null, loading: true };
		case CAMPAIGN_DELETED:
			return { ...state, response: action.payload, loading: false };

		case STOPPING_CAMPAIGN:
			return { ...state, response: null, stopping: true };
		case CAMPAIGN_STOPPED:
			return { ...state, response: action.payload, stopping: false };

		case SENDING_REMINDER:
			return { ...state, response: null, loading: true };
		case REMINDER_SENT:
			return { ...state, response: action.payload, loading: false };

		case SENDING_ESCALATION:
			return { ...state, response: null, loading: true };
		case ESCALATION_SENT:
			return { ...state, response: action.payload, loading: false };

		case FETCHING_REMINDERS_COUNT:
			return { ...state, reminders: 0, loading: true };

		case REMINDERS_COUNT_FETCHED:
			return { ...state, reminders: action.payload.data, loading: false };

		case FETCHING_ESCALATIONS_COUNT:
			return { ...state, escalations: 0, loading: true };

		case ESCALATIONS_COUNT_FETCHED:
			return { ...state, escalations: action.payload.data, loading: false };

		default:
			return state;
	}
}
