import { COVER_FILE_VALIDATED, SCORM_FILE_VALIDATED, VALIDATING_COVER_FILE, VALIDATING_SCORM_FILE } from '../actions/';

const initState = {
	loading: true,
	response: null,
};

export default function (state = initState, action) {
	switch (action.type) {
		case VALIDATING_SCORM_FILE:
			return { ...state, response: null, loading: true };
		case SCORM_FILE_VALIDATED:
			return { ...state, response: action.payload, loading: false };
		case VALIDATING_COVER_FILE:
			return { ...state, response: null, loading: true };
		case COVER_FILE_VALIDATED:
			return { ...state, coverResponse: action.payload, loading: false };
		default:
			return state;
	}
}
